<template>
    <div class="bg-white">
      <h2 class="sr-only">{{ category.name }}</h2>
      <h2 class="text-xl md:text-3xl font-poppins">{{ category.name }}</h2>
        <div class="max-w-2xl mx-auto py-6 px-4 sm:py-16 sm:px-6 lg:max-w-7xl lg:px-8">

            <div v-if="category.products.length > 0" class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                <router-link v-for="product in category.products" :key="product.id" :to="product.get_absolute_url" class="group">
                    <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                        <img :src="product.get_thumbnail" :alt="product.name" class="w-full h-full object-center object-cover group-hover:opacity-75" />
                    </div>
                  <div class="grid grid-cols-2 content-center">
                    <div class="text-sm text-gray-700 items-baseline p-1">
                      {{ product.name }}
                    </div>
                    <p class="text-lg font-medium text-gray-900 text-right">
                      {{ product.price }} €
                    </p>
                  </div>
                </router-link>
            </div>
            <div v-else class="font-poppins text-gray-600">
              There is no product in this category.
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { createToast } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css'

    export default {
        name: "Category",
        data () {
            return {
                category: {
                    products: []
                }
            }
        },
        mounted() {
            this.getCategory()
        },
        watch: {
            $route(to, from) {
                if (to.name === 'Category') {
                    this.getCategory()
                }
            }
        },
        methods: {
            async getCategory() {
                this.$store.commit('setIsLoading', true);

                const category_slug = this.$route.params.category_slug;
                console.log(category_slug)

                await axios
                    .get(`/api/v1/product/${category_slug}`)
                    .then(response => {
                        this.category = response.data;

                        document.title = this.category.name + ' | Durban'
                    })
                    .catch(error => {
                        console.log(error);

                        createToast('Something went wrong. Please Try again.',
                            {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'bounce',
                            })
                    });

                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>

<style scoped>

</style>
